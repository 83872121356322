import React from 'react'
import { useDispatch } from 'react-redux'
import MaterialUICollapseMenu from 'material-ui-collapse-menu'
import { makeStyles } from '@material-ui/core'
import {  map } from 'lodash'
import { userList } from '../../actions/user'
import { Separator } from '../../components/layout/Separator'
import { Button } from '../../components/layout/Button'
import { clearAuthentication } from '../../actions/auth'

const useStyles = makeStyles((theme) => ({
    content: {
        marginBottom: "10px",
        paddingLeft: 15,
        paddingRight: 15,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 7,
            paddingRight: 7,
        },
        backgroundColor: "lightgray",
        display: "flex",
        textAlign: "center",
        justifyContent: "space-between",
    },
    brand: {
        display: "flex",
        textAlign: "center",
        color: "blue"
    }
}))

const ALL_MENU_ITEMS = [
          {
            "id": "ledger_transactions",
            "name": "Ledger Transactions",
            "link": "/admin/ledger_transactions"
          },
          {
            "id": "promotional_medias",
            "name": "Promotional Media",
            "link": "/admin/promotional_medias"
          },
          {
            "id": "albums",
            "name": "Albums",
            "link": "/admin/albums"
          },
          {
            "id": "gallery_events",
            "name": "Gallery Events",
            "link": "/admin/gallery_events"
          },
          {
            "id": "photo_arts",
            "name": "Photo Art",
            "link": "/admin/photo_arts"
          },
          {
            "id": "genre_tags",
            "name": "Genre Tags",
            "link": "/admin/genre_tags"
          },
          {
            "id": "curency_conversions",
            "name": "Currency Conversions",
            "link": "/admin/currency_conversions"
          },
          {
            "id": "country_print_configurations",
            "name": "Country Print Configurations",
            "link": "/admin/country_print_configurations"
          },
          {
            "id": "facial_recognition",
            "name": "Face Recognition",
            "link": "/admin/facial_recognition"
          },
          {
            "id": "auto_crop",
            "name": "Auto Crop (cloudinary)",
            "link": "/admin/auto_crop"
          },
          {
            "id": "cache",
            "name": "Reset Cache",
            "link": "/admin/cache"
          },
          {
            "id": "vouchers",
            "name": "Vouchers",
            "link": "/admin/vouchers"
          },
          {
            "id": "celery_queue",
            "name": "Celery queue",
            "link": "/admin/celery_queue"
          }
]

export const AdminMenu = () => {
    const classes = useStyles()
    
    const dispatch = useDispatch()
    const logged_in_user = userList.getUser()

    let allowed_menu_keys
    if ( !logged_in_user.is_superuser ) {
       allowed_menu_keys = []
    }
    const menu_items = ALL_MENU_ITEMS

    const onLogout = () => {
        dispatch(clearAuthentication())
    }

    const renderProfileMenu = () => {

        return (
            <div>
              <Button onClick={onLogout}>Logout</Button>
            </div>
        )
    }

    const renderMenu = () => {
        return (
            <div>
              {map(menu_items, (menu_item) => (
              <>
                <a href={menu_item.link}>{menu_item.name}</a>
            &nbsp;&nbsp;&nbsp;&nbsp;
              </>
              ))}
            <br/>
            </div>
        )
                                   
    }
    
    return (
        <div className={classes.content}>
          <div>
            <div className={classes.brand}>BOSSFOTOS Admin Site</div>
            <Separator width="20px" />
            { renderMenu() }
          </div>
          
          <div>
            {renderProfileMenu()}
          </div>
          
        </div>
    )
}
