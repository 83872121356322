import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AdminDashboard } from './AdminDashboard'
import { AdminGenreTags } from './AdminGenreTags'
import { AdminPhotoArts } from './AdminPhotoArts'
import { AdminAlbums } from './AdminAlbums'
import { AdminGalleryEvents } from './AdminGalleryEvents'
import { AdminFacialRecognition } from './AdminFacialRecognition'
import { AdminPromotionalMedias } from './AdminPromotionalMedias'
import { AdminLedgerTransactions } from './AdminLedgerTransactions'
import { AdminCurrencyConversions } from './AdminCurrencyConversions'
import { AdminCountryPrintConfiguration }  from './AdminCountryPrintConfiguration'
import { AdminCountryPrintConfigurations }  from './AdminCountryPrintConfigurations'
import   AdminGenreTag  from './AdminGenreTag'
import   AdminPhotoArt  from './AdminPhotoArt'
import { AdminAutoCrop } from './AdminAutoCrop'
import { AdminCache } from './AdminCache'
import   AdminAlbum  from './AdminAlbum'
import   AdminGalleryEvent  from './AdminGalleryEvent'
import   AdminPromotionalMedia from './AdminPromotionalMedia'
import   AdminCurrencyConversion  from './AdminCurrencyConversion'
import { AdminVouchers } from './AdminVouchers'
import { AdminCeleryQueue } from './AdminCeleryQueue'
import AdminVoucher from './AdminVoucher'


export const AdminRouter = () => {
    return (
        <Switch>
          <Route path="/admin/dashboard" exact={ false } component={ AdminDashboard } />
          <Route path="/admin/ledger_transactions" exact={ false } component={ AdminLedgerTransactions } />

          <Route path={`/admin/albums`} component={ AdminAlbums }/>
          <Route path={`/admin/album/:album_id`} component={ AdminAlbum }/>
          <Route path={`/admin/album`} component={ AdminAlbum }/>

          <Route path={`/admin/gallery_events`} component={ AdminGalleryEvents }/>
          <Route path={`/admin/gallery_event/:gallery_event_id`} component={ AdminGalleryEvent }/>
          <Route path={`/admin/gallery_event`} component={ AdminGalleryEvent }/>

          <Route path={`/admin/photo_arts`} component={ AdminPhotoArts }/>
          <Route path={`/admin/photo_art/:photo_art_id`} component={ AdminPhotoArt }/>
          
          <Route path={`/admin/genre_tags`} component={ AdminGenreTags }/>
          <Route path={`/admin/genre_tag/:genre_tag_id`} component={ AdminGenreTag }/>
          <Route path={`/admin/genre_tag`} component={ AdminGenreTag }/>

          <Route path={`/admin/promotional_medias`} component={ AdminPromotionalMedias }/>
          <Route path={`/admin/promotional_media/:promotional_media_id`} component={ AdminPromotionalMedia }/>
          <Route path={`/admin/promotional_media`} component={ AdminPromotionalMedia }/>

          <Route path={'/admin/currency_conversion/:currency_conversion_id'} component={ AdminCurrencyConversion }/>
          <Route path={'/admin/currency_conversions'} component={ AdminCurrencyConversions }/>
          <Route path={'/admin/currency_conversion'} component={ AdminCurrencyConversion }/>

          <Route path={`/admin/country_print_configurations`} component={ AdminCountryPrintConfigurations }/>
          <Route path={`/admin/country_print_configuration/:country_print_configuration_id`} component={ AdminCountryPrintConfiguration }/>
          
          <Route path={`/admin/facial_recognition`} component={ AdminFacialRecognition }/>
          <Route path={`/admin/auto_crop`} component={ AdminAutoCrop }/>
          <Route path={`/admin/cache`} component={ AdminCache }/>

          <Route path={`/admin/vouchers`} component={ AdminVouchers }/>
          <Route path={`/admin/voucher/:voucher_id`} component={ AdminVoucher }/>
          <Route path={`/admin/voucher`} component={ AdminVoucher }/>

          <Route path={`/admin/celery_queue`} component={ AdminCeleryQueue }/>
          
        </Switch>
    )
    
}
