import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useHistory }  from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminCommonListLayout from './AdminCommonListLayout'
import { adminCeleryQueueList } from '../actions/admin_celery_queue'
import { makeStyles } from '@material-ui/core'
import { capitalize } from 'lodash'
import Timestamp from '../../components/Timestamp'

const useStyles = makeStyles((theme) => ({
  celery_queue_image: {
      maxWidth: '120px',
  }
}))

export const AdminCeleryQueue = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const is_loading = useSelector(() => adminCeleryQueueList.isLoading())

    useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(adminCeleryQueueList.fetchListIfNeeded())
    }
    fetchStaticObjects()
  }, [])

    
  const columns = [
    {
      field: 'id',
      title: 'ID',
    },
    {
      field: 'photograph_id',
      title: 'Photograph ID',
    },
    {
      field: 'gallery_id',
      title: 'Gallery ID',
    },
    {
      field: 'gallery_name',
      title: 'Gallery Name',
    },
    {
      field: 'action',
      title: 'Action',
    },
  ]

    return (

        <>
        <AdminCommonListLayout
        title="This page shows the next items in the celery queue to be processed by background tasks. You cannot paginate but you can see the total items at the bottom"
        active_key='celery_queue'
        breadcrumbs={[
            { name: 'admin_home' },
            {
                name: 'celery_queue',
                label: 'CeleryQueue',
                url: '/admin/celery_queue',
            },
        ]}
        columns={ columns }
        is_loading={ is_loading }
        item_list={ adminCeleryQueueList }
        />
        </>
    )
}
