import { ItemList } from "../../orm";
import { v4 as uuidv4 } from 'uuid'

class AdminCeleryQueueList extends ItemList {

  getEntityKey() {
    return "admin/celery_queue";
  }

}

export const adminCeleryQueueList = new AdminCeleryQueueList("admin_celery_queue__default");

